.custom_next {
  
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;

    & svg{
        color: white;
    }

    & path{
        /* fill: none; */
        stroke: grey;
        stroke-width: 1px;
        stroke-linejoin: round;
        }

    &:hover{
        background-color: rgb(0, 0, 0, 0.3);
        & svg{
            color: var(--marketplaceColor);
        }
        
    & path{
        stroke: none;
        }
    }

    @media(max-width: 900px){
        display: none;
    }

  }

  .custom_prev{
    
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;

    & svg{
        color: white;
    }

    & path{
        /* fill: none; */
        stroke: grey;
        stroke-width: 1px;
        stroke-linejoin: round;
        }

    &:hover{
        background-color: rgb(0, 0, 0, 0.3);
        & svg{
            color: var(--marketplaceColor);
        }
        
    & path{
        stroke: none;
        }
    }

    @media(max-width: 900px){
        display: none;
    }

   
  }