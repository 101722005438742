@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ---- */

@keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }

  @media (max-width: 900px) {
    flex-direction: column;
    min-height: none;
    max-height: none;
  }
}

.heroSection {
  position: relative;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
  padding-bottom: 15vh;

  @media (max-width: 900px) {
    width: 100%;
    height: 50%;
    padding-bottom: 5vh;
  }
}

.heroSection:first-child {
  background-image: url('./images/Maya_Paunovska_compressed.webp');
  background-position: center 65%;

  @media (min-width: 540px) and (max-width: 900px) {
    background-position: center 35%;
  }
}

.heroSection:last-child {
  background-image: url('./images/Leonie_Hanne_compressed.webp');
  background-position: center 35%;
}

.heroLinkNew {
  color: black;
  position: absolute;
  inset: 0;
  &:hover {
    text-decoration: none;
  }

  text-align: center;
  font-size: 32px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & div:first-child {
    margin-top: 20%;
    align-self: center;
    display: inline-block;
    background-color: var(--marketplaceColor);
    line-height: 1.1;
    padding: 0 4px;

    @media (min-width: 540px) and (max-width: 900px) {
      margin-top: 15%;
    }

    @media (min-width: 900px) {
      margin-top: 30%;
    }
  }

  & div:last-child {
    line-height: 1.1;
    color: white;
  }
}
.button {
  @apply --defaultButtonStyle;
  color: black;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
}

.heroButton {
  @apply --defaultButtonStyle;
  width: 200px;

  @media (max-width: 900px) {
    width: 75%;
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }

  &:hover {
    background-color: var(--marketplaceDarkGreen);
    text-decoration: none;
  }
}

.heroRoot {
  /* @apply --backgroundImage; */
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: url('./images/postcard_horizontal.webp');
  background-size: cover;

  @media (max-width: 768px) {
    background: url('./images/postcard_vertical.webp');
    background-size: cover;
  }

  /* Add loading color for the div */
  background-color: var(--matterColor);

  /* Align the image within the container */
  background-position: center center;

  @media (max-width: 768px) {
    background-position: center 75%;
  }

  /* @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  } */

  /* --- */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 15vh;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    padding-top: 60px;
  }
}

.thenoldContainer {
  display: flex;
  justify-content: center;
  padding: 40px;
  gap: 20px;
  color: black;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }

  & > * {
    flex: 1;
  }
}

.thenoldContainer__main {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  & h1 {
    margin: 0;
    line-height: 1.1;
    font-size: 1.8em;
    font-weight: 500;
  }
}

.thenoldContainer__item {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & p,
  & h2 {
    margin: 0;
  }

  & h2 {
    display: flex;
    gap: 10px;

    & span:first-child {
      margin-top: 6px;
      font-size: 2.5em;
      letter-spacing: -4px;

      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }

    & span:last-child {
      font-size: 0.9em;
      white-space: pre-line;
      line-height: 1.3;
    }
  }

  & p {
    line-height: 1.5;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  width: 100%;
  padding: 40px;
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */

.passwordWrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.passwordInput {
  width: 400px;
  border: 2px solid black;
  margin-top: 40px;
  border-radius: 10px;
  padding-left: 10px;

  @media (max-width: 900px) {
    width: 70vw;
  }
}

.categoriesWrapper {
  min-width: 300px;
  display: flex;
  justify-content: center;
  padding: 20px 0;

  @media (max-width: 768px) {
    display: none;
  }
}

.category {
  width: 10vw;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #262626;
  font-weight: 200;
}

.sellButton {
  @apply --defaultButtonStyle;
  text-align: center;
  display: flex;
  justify-self: center;
  align-items: center;
  align-content: center;
  min-width: 150px;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
}

.gifIcon {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.iconRotate {
  font-size: 50px !important;
  transform: rotate(180deg);
}

.infoTextUp {
  font-size: 30px;
}

.infoTextBottom {
  line-height: 22px;
  margin-top: 20px;
  color: rgb(195, 195, 195);
  font-weight: 300;
  padding: 0 10px;
  width: 100%;
}

.infoTextBottomGif {
  line-height: 22px;
  margin-top: 20px;
  color: rgb(195, 195, 195);
  font-weight: 300;
  text-align: center;
}

.howToSellText {
  font-size: 30px;
  @media (max-width: 768px) {
    line-height: 35px;
    width: 100%;
  }
}

.sectionTitle {
  font-size: 30px;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 25px;
  }
}

.title {
  line-height: 20px;
}

.spotlightListingWrapper {
  width: 100%;
  min-width: 150px;
  height: 300px;
  margin: 10px;
  background-color: #faf5ef;
  cursor: pointer;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 20px;
    border: 5px solid white;
    min-width: none;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.spotlightListingWrapper2 {
  width: 100%;
  min-width: 150px;
  height: 300px;
  margin: 10px;
  background-color: #faf5ef;
  cursor: pointer;
  -webkit-animation: fadein2 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein2 1s; /* Firefox < 16 */
  -ms-animation: fadein2 1s; /* Internet Explorer */
  -o-animation: fadein2 1s; /* Opera < 12.1 */
  animation: fadein2 1s;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 20px;
    border: 5px solid white;
    min-width: none;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.authorTag {
  margin-left: 10px;
  @media (max-width: 768px) {
    padding: 5px;
  }
}

.authorPic {
  height: 30px;
  width: 30px;
  border-radius: 111px;
  object-fit: cover;
}

.weLoveItemImage {
  width: 100%;
  height: 400px;
  object-fit: cover;

  @media (max-width: 768px) {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 300px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.weLoveItemWrapper {
  /* padding: 0 10px; */
  background-color: #faf5ef;
  cursor: pointer;
  border-right: 18px solid white;

  @media (max-width: 768px) {
    width: 100%;
    border-right: 10px solid white;
    /* height: 300px; */
  }
}

.storiesSectionWrapper {
  width: 100%;
}

.howToSellStepWrapper {
  height: 100%;
  width: 100%;
  border-right: 4px solid #e2f79d;
  padding-left: 20px;
  @media (max-width: 900px) {
    border-right: none;
    border-bottom: 4px solid #e2f79d;
    padding: 20px 0;
  }
}

.howToSellStepWrapper2 {
  height: 100%;
  width: 100%;
  padding-left: 20px;
  @media (max-width: 900px) {
    border-right: none;
    border-bottom: 4px solid #e2f79d;
    padding: 20px 0;
  }
}

.weLoveItemText {
  display: flex;
  background-color: white;
  width: 100%;
  justify-content: space-between;
  /* padding: 0 10px; */
  color: black;
}

.icon {
  height: auto;
  width: 40px;
  margin-right: 15px;
}

.shareIcon {
  height: auto;
  width: 35px;
  margin-right: 15px;
  object-fit: contain;
}

.piggyIcon {
  height: auto;
  width: 40px;
  margin-right: 15px;
  margin-top: -5px;
}

.seeMoreButton {
  @apply --defaultButtonStyle;
  min-width: 200px;
  margin-top: 20px;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.infoSection {
  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  background-color: #e2f79d;
  color: black;

  & h2 {
    margin: 0 0 10px 0;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.2;
  }
  & h3 {
    display: inline-block;
    margin: 0 0 20px 0;
    font-size: 1.5em;
    line-height: 1;
    padding: 5px 10px;
    background-color: black;
    color: white;
    font-weight: 500;
  }
}

.infoBox {
  position: relative;
  isolation: isolate;
  background-color: white;

  padding: 20px;
  font-weight: 400;

  & strong {
    font-weight: 500;
  }

  /* &:before {
    z-index: -1;
    transform: rotate(-2deg) scale(1.05);
    background-color: rgba(255, 255, 255, 1);
    content: '';
    position: absolute;
    inset: 0;
  } */

  & li a {
    color: black;
    text-decoration: underline;
  }

  & p {
    margin: 0 0 20px 0;
    line-height: 1.3;
    font-weight: 400;
    font-size: 16px;
  }

  & ol {
    margin: 0 0 30px 0;
    padding-left: 20px;
    font-size: 16px;

    & li {
      margin-bottom: 12px;
      line-height: 1.3;
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.infoButtons {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
  }

  & .button {
    background-color: black;
    color: white;
    font-weight: 500;
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}
