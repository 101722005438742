@import '../../../styles/customMediaQueries.css';
@import '../../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: url('../../../assets/background-desktop.jpg');
  background-color: var(--matterColor);
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    background: url('../../../assets/background-mobile.jpg');
  }
  /* @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  } */
}

.heroContent {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-left: 5vw;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColor);

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton1 {
  @apply --defaultButtonStyle;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* @apply --marketplaceButtonStyles; */
  composes: animation;
  text-align: center;
  animation-delay: 0.8s;
  margin-top: 20px;
  line-height: 22px;
  @media (--viewportMedium) {
    width: 180px;
    margin: 15px;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 200px;
  }

  &:hover {
    background-color: var(--marketplaceHoverGreen);
  }
}

.heroButton2 {
  @apply --defaultButtonStyle;
  color: black;
  composes: animation;

  animation-delay: 0.8s;
  margin-top: 20px;
  @media (--viewportMedium) {
    width: 180px;
    margin: 15px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    background-color: var(--marketplaceHoverGreen);
  }
}
