.storiesWrapper{
    width: 100%;
    padding: 0 20vw;
    margin-bottom: 40px;
  
    @media (max-width: 768px){
      width: 100%;
      padding: 20px 0;
      margin-bottom: 0;
    }
}


.addIcon{
    color: rgb(83, 83, 83);
}



.uploadStoryButton{
    height: 60px;
    width: 60px;
    border-radius: 111px;
    cursor: pointer;

    background-color: rgb(217, 217, 217);
    border: 3px solid rgb(190, 190, 190);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    &:hover{
        border-color: var(--marketplaceGreen);
    }
}

.slideWrapper{
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;

}


.storyIconWrapper{
    border-radius: 111px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid rgb(226, 226, 226);
    &:hover{
        transform: scale(1.02);
    }
}

.storyUserImage{
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 111px;
    border: 3px solid var(--marketplaceGreen);
    margin: auto 0;
    object-fit: cover;

}

.storiesSlidesWrapper{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999999;

}

.storiesSlidesWrapper > div {
    margin: 0 auto;

    /* @media(max-width: 768px){
        width: 100% !important;
    } */
}   

.storiesWrapperBackgroundColor{
    background-color: #111111;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.closeIconWrapper{
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: 999999;
    cursor: pointer;

    @media(max-width: 768px){
        top: 30px;
        right: 30px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 5px;
        border: 1px solid grey;
    }
}

.closeIconWrapper > svg{
    font-size: 25px;
    @media(max-width: 768px){
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(104deg) brightness(103%) contrast(106%);    
    }
}


.storiesWrapper img{
    object-fit: cover !important;
}

